import React, { useState } from 'react';
import people from './data';
import { FaChevronLeft, FaChevronRight, FaQuoteRight } from 'react-icons/fa';
import "../styles.css";
import i18n from "./i18n.ts";
import { useTranslation } from "react-i18next";

const Review = () => {
  const [index, setIndex] = useState(0);
  const {name, job, image, text} = people[index];
  const { i18n, t } = useTranslation();

  const checkNumber = (number) => {
    if(number > people.length - 1){
      return 0;
    }
    else if(number < 0){
      return people.length - 1;
    }
    return number;
  }

  const nextPerson = () => {
    setIndex((index) => {
      let newIndex = index + 1;
      return checkNumber(newIndex);
    })
  };

  const prevPerson = () => {
    setIndex((index) => {
      let newIndex = index - 1;
      return checkNumber(newIndex);
    })
  }

  const randomPerson = () => {
    let randomNumber = Math.floor (Math.random() * people.length);
    if(randomNumber === index ){
      randomNumber = index + 1;
    }
    setIndex(checkNumber(randomNumber));
  }

  return<article className="review">
  <h4 className="author">{'What Our Customers Say'}</h4>
  <div className="rating">
      <input type="radio" name="rating" value="5" id="5"/><label for="5">★</label>
      <input type="radio" name="rating" value="4" id="4"/><label for="4">★</label>
      <input type="radio" name="rating" value="3" id="3"/><label for="3">★</label>
      <input type="radio" name="rating" value="2" id="2"/><label for="2">★</label>
      <input type="radio" name="rating" value="1" id="1"/><label for="1">★</label>
   </div>
    <div className="aimg-container">
      {/*<img src={image} alt={name} className="person-img"/>*/}
     {/*<span className="quote-icon">
        <FaQuoteRight />
      </span>*/}
      </div>

      <h4 className="author">{name}</h4>
      {/*<p className="jon">{job}</p>*/}
      <p className="info">{text}</p>
      <div className="button-container">
        <button className="prev-btn" onClick={prevPerson}>
          <FaChevronLeft />
        </button>
        <button className="next-btn" onClick={nextPerson}>
          <FaChevronRight />
        </button>
      </div>
      {/*<button className="random-btn" onClick={randomPerson}>
          Random Review!
        </button>*/}
  </article>;
};

export default Review;
