const actions = {
  GET_FLIGHT_LIST: "GET_FLIGHT_LIST",
  GET_FLIGHT_LIST_SUCCESS: "GET_FLIGHT_LIST_SUCCESS",
  GET_FLIGHT_LIST_ERROR: "GET_FLIGHT_LIST_ERROR",
  RESET_FLIGHT_LIST: "RESET_FLIGHT_LIST",

  SET_BOOKING_DETAILS: "SET_BOOKING_DETAILS",
  SET_GOOGLE_USER: "SET_GOOGLE_USER",
  GET_GOOGLE_USER: "GET_GOOGLE_USER",
  SET_USER_CREDITS: "SET_USER_CREDITS",
  SET_USER_FIRSTNAME: "SET_USER_FIRSTNAME",
  SET_USER_LASTNAME:  "SET_USER_LASTNAME",
  SET_USER_OBJECT: "SET_USER_OBJECT",
  SET_SUBMIT_DATA: "SET_SUBMIT_DATA",
  SET_PAYMENT_FORM_OPEN: "SET_PAYMENT_FORM_OPEN",
  SET_PAYMENT_SUCCESS: "SET_PAYMENT_SUCCESS",
  SET_PRICE: "SET_PRICE",
  SET_SERVICE_TYPE: "SET_SERVICE_TYPE",
  SET_APPLY_NOW_BUTTON: "SET_APPLY_NOW_BUTTON",

};

export default actions;
