import { takeLatest, call } from "redux-saga/effects";

import actions from "../actions";
import * as worker from "./worker";

export function* watchFlightSearch() {
  try {
    yield takeLatest(actions.GET_FLIGHT_LIST, function* (action) {

      yield call(worker.fetFlightList, action.payload);
    });
  } catch (e) {
    console.log("DUDU");
 yield put({ type: 'USER_FETCH_FAILED', message: e.message })
}
}
