import { combineReducers } from "redux";

import flightSearch from "./components/search/reducer.js";
import userInfo from "./components/reducer.js";

const rootReducer = combineReducers({
  flightSearch,
  userInfo
});

export default rootReducer;
