//import { combineReducers } from "redux";

import actions from "./actions.js";

const defaultState = {
  loading: null,
  result: null,
  error: null
};

const userInfo = (state = defaultState, action) => {

  switch (action.type) {
    case actions.SET_USER_OBJECT:
      return Object.assign({}, state, {
        loading: false,
        result: action.data,
        error: null
      });

    default:
      return state;
  }
};


export default userInfo;
