//import { put, call } from "redux-saga/effects";

import actions from "../actions";
import { filterBySourceDest } from "../global-services";
import { useDispatch, useSelector } from "react-redux";
import { get } from "../../xhr.js";

//import FlightJSON from "../mocks/flights-one-way-mumbai.json";

export async function fetFlightList(payload,dispatch) {

  // put API URL here

  let url1 =
    "https://aviation-edge.com/v2/public/flights?key=9ef704-e9f7b6&airlineIcao=" +
    payload.source +
    "&flightNum=" +
    payload.flightnum;
  let url2 =
    "https://airlabs.co/api/v9/routes?api_key=f250f4ba-57ad-462c-b988-e669aa89d652&airline_icao=" +
    payload.source +
    "&flight_number=" +
    payload.flightnum;

  //const jsonResponse = [...FlightJSON];
  try {
    // uncomment when API is available
  //  const response = yield call(get, url1);
    console.log(url2);
    //const response1 = await fetch(url1);
    const response2 = await fetch(url2);
    //const json1 = await response1.json();
    const json1 = ["new real time"]
    const json2 = await response2.json();
    //const json2="Wait"

    //console.log(json1);
    //const response2 = yield call(get, url2);
    //console.log(json2);
    console.log(json2['response']);
    const jsonresponse = [json1,json2['response']];


    if (json2.error=="No Record Found")
    {
      throw Error("No Records Found");
    }


    //const response = filterBySourceDest(payload, jsonResponse);

    dispatch({
      type: actions.GET_FLIGHT_LIST_SUCCESS,
      result: jsonresponse,
      error: null
    });
  } catch (error) {
    console.log(error.message);
    const errorObj = error.message;

    dispatch({
      type: actions.GET_FLIGHT_LIST_ERROR,
      result: null,
      error: {
        statusCode: errorObj,
        message: errorObj
      }
    });
  }
}
