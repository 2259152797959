import React from "react";
import "../styles.css";

function Intro() {
  return (
    <div className="Intro1">
      <h5>
        AMFI app push alerts your friends & family your flight information
        during takeoff, landing and cool updates while on air!
      </h5>
    </div>
  );
}
export default Intro;

//416913523020-skl8c263bg31buo05lh3cj6sc02560k0.apps.googleusercontent.com

//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
