const reviews = [
  {
    id: 4,
    name: 'Bill Anderson',
    job: 'Store Manager',
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883423/person-4_t9nxjt.jpg',
    text:
      'I am completly content with the result of the work on my visa! No registration and additional password needed. Your first request is practically the registration. I\'ve submitted my request in advance so they gave me an exact date when my request will be worked on. The given date was the day when I received it. Thank you!!!',
  },
  {
    id: 3,
    name: 'David Barlowe',
    job: 'Architect',
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883417/person-3_ipa0mj.jpg',
    text:
      'Fast and easy visa process. I was kept informed of the progress of my application on Whatsapp. I would definitely recommend them',
  },
  {
    id: 1,
    name: 'Amelia Miller',
    job: 'Globe Trotter ',
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883334/person-1_rfzshl.jpg',
    text:
      "Extremely efficient visa process and quick and faster than any other site without the gobbledygook  !! Well worth paying the extra for peace of mind!!!!!!!",
  },
  {
    id: 5,
    name: 'Ali Ahmad',
    job: 'Architect',
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883417/person-3_ipa0mj.jpg',
    text:
      'Their staff is not only friendly but also highly skilled on getting me my visa, highly recommend',
  },
  {
    id: 2,
    name: 'Anna Bald',
    job: 'Web Designer',
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883409/person-2_np9x5l.jpg',
    text:
      'The process was amazing! all process of AI based is really smooth, very fast honest and reliable I was thrilled with quick response amazing people professionals in there job keep up guys great work on visa'
  },
  {
    id: 3,
    name: 'Grace Turlington',
    job: 'Architect',
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883417/person-3_ipa0mj.jpg',
    text:
      'Fast and easy. I was kept informed of the progress of my application on Whatsapp. I would definitely recommend them, got my visa so quickly!',
  },

  {
    id: 8,
    name: 'Sara Habib',
    job: 'Store Manager',
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883423/person-4_t9nxjt.jpg',
    text:
      'I was completely impressed with their professionalism and customer service for my visa request',
  },
  {
    id: 6,
    name: 'Eric Johnson',
    job: 'Store Manager',
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883423/person-4_t9nxjt.jpg',
    text:
      'Visa pricing is fair and transparent - definitely value for money',
  },
  {
    id: 7,
    name: 'Joanna Le Page',
    job: 'Store Manager',
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883423/person-4_t9nxjt.jpg',
    text:
      'They did an amazing job with my Visa request. The team was professional, efficient, and exceeded my expectations. I highly recommend them!',
  }
];

export default reviews;
