import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import "./styles.css";
import { createRoot } from 'react-dom/client';
import App from "./App";
import * as Globals from "./components/config/GLOBALS.js";


import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import 'regenerator-runtime/runtime'
import createSagaMiddleware from "redux-saga";

import rootReducer from "./rootReducer";
import rootSaga from "./sagas";

//const sagaMiddleWare = createSagaMiddleware();
//const store = createStore(rootReducer, {}, applyMiddleware(sagaMiddleWare));
const store = createStore(rootReducer);
//sagaMiddleWare.run(rootSaga);

const rootElement = document.getElementById("root");
createRoot(rootElement).render(
  <Provider store={store}>
    <GoogleReCaptchaProvider
     reCaptchaKey={Globals.SITE_KEY}
     language="[optional_language]"
     useRecaptchaNet="[optional_boolean_value]"
     useEnterprise="[optional_boolean_value]"
     scriptProps={{
       async: false, // optional, default to false,
       defer: false, // optional, default to false
       appendTo: 'head', // optional, default to "head", can be "head" or "body",
       nonce: undefined // optional, default undefined
     }}>


    <App />
    </GoogleReCaptchaProvider >
  </Provider>
);
